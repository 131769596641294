import { GetServerSideProps } from 'next';
import { useEffect } from 'react';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import FullPageLoading from '@/components/FullPageLoading';
import { useTeamInformation } from '@/models/TeamInformation';
import { useUserProfile } from '@/models/UserProfile';
import {
  ColumnPermissionsProvider,
  ComputerListPageContainer,
  ComputerListPageLayout,
  MyComputersSBA,
  MyComputersSRS,
  OOBE,
} from '@/modules/Computer';
import { useColumnPermissions } from '@/modules/Computer/MyComputers/useColumnPermissions';
import { useComputerDataSBA, useComputerDataSRS } from '@/modules/Computer/MyComputers/useComputerData';
import { UserPermissionSBAInstance } from '@/modules/Computer/MyComputers/userPermission';
import getLocale from '@/utils/getLocale';
import { useZendesk } from '@/utils/useZendesk';

/**
 * Currently only supported for SRS team and SBA team
 */
const MyComputersPage = () => {
  const { teamKind } = useTeamInformation();
  const { email } = useUserProfile();

  useZendesk({ product: '', email });

  const isSplashtopPersonal = teamKind === 'stp';
  const isSplashtopSOS = teamKind === 'sos';
  const isSplashtopBusiness = teamKind === 'sba';

  useEffect(
    function handleSplashtopPersonalAndSOS() {
      if (isSplashtopPersonal || isSplashtopSOS) {
        window.location.href = '/computers/stp';
      }
    },
    [isSplashtopPersonal, isSplashtopSOS],
  );

  if (isSplashtopPersonal || isSplashtopSOS) return <FullPageLoading />;

  if (isSplashtopBusiness) return <MyComputersPageCoreSBA />;

  return <MyComputersPageCoreSRS />;
};

function MyComputersPageCoreSRS(): React.JSX.Element {
  const columnPermissions = useColumnPermissions();
  const { showOOBE } = useComputerDataSRS();

  if (showOOBE === null) return <FullPageLoading />;

  if (showOOBE) return <OOBE kind="user" />;

  return (
    <ComputerListPageLayout>
      <ComputerListPageContainer>
        <ColumnPermissionsProvider value={columnPermissions}>
          <MyComputersSRS />
        </ColumnPermissionsProvider>
      </ComputerListPageContainer>
    </ComputerListPageLayout>
  );
}

function MyComputersPageCoreSBA(): React.JSX.Element {
  const columnPermissions = useColumnPermissions();
  const { showOOBE } = useComputerDataSBA();

  if (showOOBE === null) return <FullPageLoading />;

  if (showOOBE) return <OOBE kind="user" />;

  return (
    <UserPermissionSBAInstance.UserPermissionProvider>
      <ComputerListPageLayout>
        <ComputerListPageContainer>
          <ColumnPermissionsProvider value={columnPermissions}>
            <MyComputersSBA />
          </ColumnPermissionsProvider>
        </ComputerListPageContainer>
      </ComputerListPageLayout>
    </UserPermissionSBAInstance.UserPermissionProvider>
  );
}

MyComputersPage.displayName = 'MyComputersPage';
MyComputersPage.showTryBeforeCommit = true;

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  return {
    props: {
      ...(await serverSideTranslations(getLocale(req.headers['accept-language']), [
        'common',
        'computer',
        'support-session',
        'setup',
        'welcome',
        'team',
        'temp',
      ])),
    },
  };
};

export default MyComputersPage;
